import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@mui/material';

import { useAppSelector } from '../../../hooks';

import { convertDateToFormat } from '../../../../helpers';
import { MultiselectItem } from '../../interfaces';

import EllipsisText from '../../EllipsisText';
interface AppliedFiltersProps {
    filters: Record<string, any>;
    defaultFilters: any;
    updateFilters: (event: Record<string, any>) => void;
}

/**
 * Component used to render chips for each selected filter value
 *
 * @param {AppliedFiltersProps} props - The component props.
 * @param {Record<string, any>} props.filters - The applied filters.
 * @param {any} props.defaultFilters - The default filters.
 * @param {(event: Record<string, any>) => void} props.updateFilters - The function to update the filters.
 * @return {JSX.Element} The rendered component.
 */
const AppliedFilters = ({
    filters,
    defaultFilters,
    updateFilters,
}: AppliedFiltersProps): JSX.Element => {
    const { t } = useTranslation();
    const { formats } = useAppSelector((state) => state.commonData);

    const handleUpdateFilters = useCallback(
        (filterItem: string, filterValue: object) => {
            let currentFilter = filters[filterItem];
            if (Array.isArray(currentFilter)) {
                currentFilter = currentFilter.filter((item) => item !== filterValue);
                filters[filterItem] = currentFilter;
            } else {
                filters[filterItem] = defaultFilters[filterItem];
            }

            updateFilters(filters);
        },
        [defaultFilters, filters, updateFilters]
    );

    const formatFilters = useCallback(
        (data: Record<string, any>): ReactNode[] => {
            const chips: ReactNode[] = [];
            Object.keys(data).forEach((item) => {
                switch (item) {
                    case 'time':
                        {
                            let isDisabled = false;
                            if (defaultFilters[item] === data[item]) {
                                isDisabled = true;
                            }
                            chips.push(
                                <Chip
                                    className="chipStyle"
                                    label={
                                        <EllipsisText variant="body2" maxWidth={220}>
                                            {`${convertDateToFormat(
                                                data[item].start,
                                                formats.momentDateFormat
                                            )} - ${convertDateToFormat(
                                                data[item].end,
                                                formats.momentDateFormat
                                            )}`}
                                        </EllipsisText>
                                    }
                                    disabled={isDisabled}
                                    key={item}
                                    onDelete={() => handleUpdateFilters(item, data[item])}
                                />
                            );
                        }
                        break;
                    case 'transactionStatus':
                    case 'logLevel':
                    case 'tags':
                        if (Array.isArray(data[item])) {
                            data[item].forEach((status: MultiselectItem) => {
                                chips.push(
                                    <Chip
                                        className="chipStyle"
                                        label={
                                            <EllipsisText variant="body2" maxWidth={220}>
                                                {t(status.name)}
                                            </EllipsisText>
                                        }
                                        key={`${item}${status.id}`}
                                        onDelete={() => handleUpdateFilters(item, status)}
                                    />
                                );
                            });
                        }
                        break;
                }
            });

            return chips;
        },
        [formats.momentDateFormat, handleUpdateFilters]
    );

    return (
        <Box className="appliedFilters">
            <Typography variant="subtitle2">{t('general.labels.appliedFilters')} </Typography>

            {formatFilters(filters).map((chip) => chip)}
        </Box>
    );
};

export default AppliedFilters;
